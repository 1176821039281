import { axiosPrivate } from "api/axios";
import { updateLoader, updateToastAgent, setCustomers, setCustomer } from "app/appSlice";
import * as API from "constants/ApiEndpoint";

export const getAppUsers = (req) => async (dispatch) => {
  try {
    dispatch(updateLoader(true));
    let response = await axiosPrivate.get(API.GET_APP_USERS);
    if (response.data.status_code == 200) {
      let appUserData = response.data.data; 
      let activeUsers = appUserData.filter((item)=>{return item.is_complete===1})
      // console.log(activeUsers)
      dispatch(setCustomers(activeUsers));
    } else {
      dispatch(setCustomers([]));
    }
    dispatch(updateLoader(false));
  } catch (error) {
    dispatch(updateToastAgent({ t: "e", m: error.message }));
    dispatch(updateLoader(false));
  }
};

export const getAppUser = (req) => async (dispatch) => {
  try {
    dispatch(updateLoader(true));
    let response = await axiosPrivate.get(API.GET_APPUSER({ id: req.id }));
    console.log(response);
    if (response.data.status_code == 200) {
      let resultData = {
        first_name: response.data.data.first_name,
        last_name: response.data.data.last_name,
        dob: response.data.data.dob,
        nationality: response.data.data?.CountryModel?.name,
        marital_status: response.data.data.marital_status,
        id_number: response.data.data.id_number,
        expiry: response.data.data.expiry,
        id: response.data.data.id,
        email: response.data.data.email,
        phone: response.data.data.phone,
        payslip: response.data.data.payslip,
        address_image: response.data.data.address_image,
        profile: response.data.data.profile,
        document: response.data.data.document,
        document_back: response.data.data.document_back,
        status: response.data.data.status,
        role: 4,
        bank_account : response.data.data.bank_account , 
        bank_name : response.data.data.bank_name , 
        bban :response.data.data.bban , 
        iban:response.data.data.iban , 
        affordability : response.data.data.affordability ,
        average_monthly_salary : response.data.data.average_monthly_salary , 
        credit_score : response.data.data.credit_score , 
        expenses : response.data.data.expenses , 
        monthly_affordability_amount : response.data.data.monthly_affordability_amount , 
        net_worth : response.data.data.net_worth, 
        on_time_payment_percentage : response.data.data.on_time_payment_percentage , 
        forecast : response.data.data.forecast
      };
      dispatch(setCustomer(resultData));
    }
    dispatch(updateLoader(false));
  } catch (error) {
    dispatch(updateToastAgent({ t: "e", m: error.message }));
    dispatch(updateLoader(false));
  }
};

export const updateUser = (req, redirectTo, navigate) => async (dispatch) => {
  try {
    dispatch(updateLoader(true));
    let results = await axiosPrivate.post(API.USER, req);
    if (results.data.status_code == 200) {
      dispatch(updateToastAgent({ t: "s", m: results.data.message }));
      if (redirectTo) {
        navigate(redirectTo);
      }
    } else {
      dispatch(updateToastAgent({ t: "e", m: results.data.message?.name ?? results.data.message }));
    }
    dispatch(updateLoader(false));
  } catch (error) {
    dispatch(updateToastAgent({ t: "e", m: error.message }));
    dispatch(updateLoader(false));
  }
};

export const changeStatus = (req) => async (dispatch) => {
  try {
    dispatch(updateLoader(true));
    let results = await axiosPrivate.get(API.CHANGE_STATUS({ id: req.id, status: req.status }));
    if (results.data.status_code == 200) {
      dispatch(updateToastAgent({ t: "s", m: results.data.message }));
    } else {
      dispatch(updateToastAgent({ t: "e", m: results.data.message }));
    }
    dispatch(updateLoader(false));
  } catch (error) {
    dispatch(updateToastAgent({ t: "e", m: error.message }));
    dispatch(updateLoader(false));
  }
};
