import { useNavigate, useParams } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import NativeSelect from "@mui/material/NativeSelect";

import { TextField } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUser, updateUser } from "api/services/userService";
import { initialState } from "app/initialState";
import { getMerchantTypes } from "api/services/merchantTypeService";
import { getCategories } from "api/services/categoryService";
import { APP_USER_LIST } from "constants/AppRouteName";
import { Formik, Form, ErrorMessage } from "formik";
import { EditUserSchema } from "app/validationSchema";
import { getUsers } from "api/services/userService";

export default function EditUser() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [LO, setLO] = useState(false);
  const { id } = useParams();
  const { user, merchantTypes, users, categories } = useSelector((state) => state.appStore);

  // console.log(user)

  useEffect(() => {
    dispatch(getUser({ id }));
    dispatch(getUsers());
    dispatch(getMerchantTypes());
    dispatch(getCategories());
  }, [id]);

  useEffect(() => {
    if (users?.length) {
      setLO(users.some((el) => el.role === "LO"));
    }
  }, [users]);

  const submitForm = (values) => {
    dispatch(updateUser(JSON.stringify(values), APP_USER_LIST, navigate));
  };

  return (
    // <CoverLayout image={bgImage}>

    <DashboardLayout>
      <DashboardNavbar />
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Edit User
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            Enter User details to Edit
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <Formik
            initialValues={user ?? initialState.editUser}
            validationSchema={EditUserSchema}
            onSubmit={submitForm}
          >
            {(formik) => {
              const { values, handleChange, errors, touched, handleBlur, isValid, dirty } = formik;
              const selectedCategoryIds = values?.deduct_percent?.map((item) =>
                Number(item.category_id)
              );
              // console.log(values)
              // console.log(selectedCategoryIds)
              return (
                <Form>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6} lg={6}>
                      <MDBox mb={2}>
                        <MDInput
                          type="text"
                          id="first_name"
                          name="first_name"
                          label="First Name"
                          value={
                            values.first_name.charAt(0).toUpperCase() + values.first_name.slice(1)
                          }
                          variant="standard"
                          fullWidth
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={
                            errors.first_name && touched.first_name ? "input-error" : "null"
                          }
                        />
                        <ErrorMessage name="first_name" component="span" className="error" />
                      </MDBox>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <MDBox mb={2}>
                        <MDInput
                          type="text"
                          id="last_name"
                          name="last_name"
                          label="Last Name"
                          value={
                            values.last_name.charAt(0).toUpperCase() + values.last_name.slice(1)
                          }
                          variant="standard"
                          fullWidth
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={errors.last_name && touched.last_name ? "input-error" : "null"}
                        />
                        <ErrorMessage name="last_name" component="span" className="error" />
                      </MDBox>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <MDBox mb={2}>
                        <MDInput
                          type="email"
                          label="Email"
                          id="email"
                          name="email"
                          value={values.email}
                          variant="standard"
                          fullWidth
                          disabled
                        />
                      </MDBox>
                    </Grid>

                    {values?.role == 2 && (
                      <>
                        <Grid item xs={12} md={6} lg={6}>
                          <MDBox mt={2}>
                            <NativeSelect
                              id="merchant_type"
                              name="merchant_type"
                              value={values.merchant_type}
                              fullWidth
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className={
                                errors.merchant_type && touched.merchant_type ? "input-error" : null
                              }
                            >
                              <option value="">Select Merchant Type</option>
                              {merchantTypes
                                ? merchantTypes.map((ty, key) => {
                                    return (
                                      <option key={key} value={ty.id}>
                                        {ty.name}
                                      </option>
                                    );
                                  })
                                : false}
                            </NativeSelect>
                            <ErrorMessage name="merchant_type" component="span" className="error" />
                          </MDBox>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                          <MDBox mt={0}>
                            <TextField
                              name="address"
                              placeholder="Address"
                              label="Address"
                              multiline
                              fullWidth
                              value={values.address}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className={errors.address && touched.address ? "input-error" : "null"}
                            />
                            <ErrorMessage name="address" component="span" className="error" />
                          </MDBox>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                          <MDBox mb={2}>
                            <MDInput
                              type="number"
                              onWheel={(e) => e.target.blur()}
                              id="registered_phone_number"
                              name="registered_phone_number"
                              label="Registered Phone Number"
                              value={values.registered_phone_number}
                              variant="standard"
                              fullWidth
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className={
                                errors.registered_phone_number && touched.registered_phone_number
                                  ? "input-error"
                                  : "null"
                              }
                            />
                            <ErrorMessage
                              name="registered_phone_number"
                              component="span"
                              className="error"
                            />
                          </MDBox>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                          <MDBox mb={2}>
                            <MDInput
                              type="number"
                              onWheel={(e) => e.target.blur()}
                              id="alternate_phone_number"
                              name="alternate_phone_number"
                              label="Alternate Phone Number"
                              value={values.alternate_phone_number}
                              variant="standard"
                              fullWidth
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className={
                                errors.alternate_phone_number && touched.alternate_phone_number
                                  ? "input-error"
                                  : "null"
                              }
                            />
                            <ErrorMessage
                              name="alternate_phone_number"
                              component="span"
                              className="error"
                            />
                          </MDBox>
                        </Grid>

                        <>
                          {values?.role === 2 && (
                            <>
                              <Grid item xs={12} md={6} lg={6}>
                                <MDBox mt={0}>
                                  <MDInput
                                    type="text"
                                    onWheel={(e) => e.target.blur()}
                                    id={`product_category_0`}
                                    name={`deduct_percent[0].category_id`}
                                    label="Product Category"
                                    variant="standard"
                                    fullWidth
                                    disabled
                                    value={
                                      categories?.filter((item, index) => {
                                        return item.id === user?.deduct_percent[0]?.category_id;
                                      })[0]?.name
                                    }
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={
                                      errors?.deduct_percent?.[0]?.deduct_percent &&
                                      touched?.deduct_percent?.[0]?.deduct_percent
                                        ? "input-error"
                                        : null
                                    }
                                  />
                                  <ErrorMessage
                                    name={`deduct_percent[0].deduct_percent`}
                                    component="span"
                                    className="error"
                                  />
                                </MDBox>
                              </Grid>
                              <Grid item xs={12} md={6} lg={6}>
                                <MDBox mb={2}>
                                  <MDInput
                                    type="number"
                                    onWheel={(e) => e.target.blur()}
                                    id={`deduct_percent_0`}
                                    name={`deduct_percent[0].deduct_percent`}
                                    label="Deduct Percent"
                                    variant="standard"
                                    fullWidth
                                    value={values.deduct_percent[0]?.deduct_percent || ""}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={
                                      errors?.deduct_percent?.[0]?.deduct_percent &&
                                      touched?.deduct_percent?.[0]?.deduct_percent
                                        ? "input-error"
                                        : null
                                    }
                                  />
                                  <ErrorMessage
                                    name={`deduct_percent[0].deduct_percent`}
                                    component="span"
                                    className="error"
                                  />
                                </MDBox>
                              </Grid>
                            </>
                          )}
                        </>

                        <Grid item xs={12} md={6} lg={6}>
                          <MDBox mt={0}>
                            <TextField
                              name="registration_number"
                              label="Merchant Registration Number"
                              multiline
                              fullWidth
                              value={values.registration_number}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </MDBox>
                        </Grid>
                      </>
                    )}
                    <Grid container item mt={4} mb={1} display="flex" justifyContent="center">
                      <MDButton
                        type="submit"
                        variant="gradient"
                        color="info"
                        className={dirty && isValid ? "" : "disabled-btn"}
                        disabled={!(dirty && isValid)}
                      >
                        Update
                      </MDButton>
                    </Grid>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        </MDBox>
      </Card>
    </DashboardLayout>

    // </CoverLayout>
  );
}
