import { useEffect, useState } from "react";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDBox from "components/MDBox";
import { Card } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { getongoingLoans } from "../../api/services/loanService";
import Tooltip from "@mui/material/Tooltip";
import MDButton from "components/MDButton";

export default function OnGoingLoan() {
  const dispatch = useDispatch();
  const { onGoingLoans } = useSelector((state) => state.appStore);
  // console.log(onGoingLoans)
  let activeLoans = [];
  function updateActiveLoans(onGoingLoans, activeLoans) {
    onGoingLoans.forEach((loan) => {
      if (!activeLoans.some((activeLoan) => activeLoan.customer_id === loan.customer_id)) {
        activeLoans.push(loan);
      }
    });
    return activeLoans;
  }
  activeLoans = updateActiveLoans(onGoingLoans, activeLoans);
  console.log(activeLoans);

  const muiCache = createCache({
    key: "mui",
    prepend: true,
  });

  const getMuiTheme = () =>
    createTheme({
      components: {
        MUIDataTableBodyCell: {
          styleOverrides: {
            root: {
              backgroundColor: "#FF0000",
            },
          },
        },
      },
    });

  const columns = [
    {
      name: "CustomerDetail.name",
      label: " Name",
      accessor: "CustomerDetail.name",
      options: {
        filter: true,
        sort: true,
        empty: true,
        display: true,
        viewColumns: true,
      },
    },
    {
      name: "LoanDetail.amount",
      label: "Loan Amount",
      accessor: "LoanDetail.amount",
      options: {
        filter: true,
        sort: true,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const loan_amount = Number(tableMeta.rowData[1]).toLocaleString();

          return <p>{loan_amount}</p>;
        },
        setCellProps: () => ({
          style: {
            textAlign: "right",
            paddingRight: "60px",
          },
        }),
      },
    },
    {
      name: "LoanDetail.product_amount",
      label: "Product Amount",
      accessor: "LoanDetail.product_amount",
      options: {
        filter: true,
        sort: true,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const product_amount = Number(tableMeta.rowData[2]).toLocaleString();

          return <p>{product_amount}</p>;
        },
        setCellProps: () => ({
          style: {
            textAlign: "right",
            paddingRight: "60px",
          },
        }),
      },
    },
    {
      name: "LoanDetail.installments",
      label: "Total Number of EMI",
      accessor: "LoanDetail.installments",
      options: {
        filter: true,
        sort: true,
        empty: true,
        setCellProps: () => ({
          style: {
            textAlign: "right",
            paddingRight: "80px",
          },
        }),
      },
    },
    {
      name: "payment_amount",
      label: "Installment Amount",
      accessor: "payment_amount",
      options: {
        filter: true,
        sort: true,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const installment_amount = Number(tableMeta.rowData[4]).toLocaleString();

          return <p>{installment_amount}</p>;
        },
        setCellProps: () => ({
          style: {
            textAlign: "right",
            paddingRight: "80px",
          },
        }),
      },
    },
    {
      name: "CustomerDetail.assessment_status",
      label: "Assessment",
      accessor: "LoanDetail.is_high_risk",
      options: {
        filter: true,
        sort: true,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          console.log(tableMeta.rowData);

          return <>{tableMeta.rowData[5] === 1 ? "Finclude" : "Manual"}</>;
        },
      },
    },
    {
      name: "LoanDetail.is_high_risk",
      label: "Is High Risk",
      accessor: "LoanDetail.is_high_risk",
      options: {
        filter: true,
        sort: true,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          // console.log(tableMeta.rowData)

          return (
            <>
              {tableMeta.rowData[6] === false ? (
                <Tooltip title="Low Risk">
                  <MDButton color="success" size="small" disabled>
                    Low Risk
                  </MDButton>
                </Tooltip>
              ) : (
                <Tooltip title="High Risk">
                  <MDButton color="error" size="small" disabled>
                    High Risk
                  </MDButton>
                </Tooltip>
              )}
            </>
          );
        },
      },
    },
  ];
  const options = {
    filter: false,
    download: false,
    print: false,
    viewColumns: false,
    search: false,
    selectableRows: "none",
  };

  useEffect(async () => {
    getongoingLoans(dispatch);
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card paddingLeft="30%">
        <MDBox>
          <CacheProvider value={muiCache}>
            <ThemeProvider theme={getMuiTheme()}>
              {onGoingLoans != null && (
                <MUIDataTable
                  title={"Ongoing Loans"}
                  data={activeLoans}
                  columns={columns}
                  options={options}
                />
              )}
            </ThemeProvider>
          </CacheProvider>
        </MDBox>
      </Card>
    </DashboardLayout>
  );
}
